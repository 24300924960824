

/* For WebKit browsers */
::-webkit-scrollbar {
    width: 6px;
    /* Change width for vertical scrollbar */
    height: 6px;
    /* Change height for horizontal scrollbar */
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* Background color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
    background: #888;
    /* Color of the scrollbar thumb */
    border-radius: 4px;
    /* Rounded corners for the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
    /* Color of the scrollbar thumb on hover */
}

/* For Firefox */
* {
    scrollbar-width: thin;
    /* Use 'auto', 'thin', or 'none' */
    scrollbar-color: #888 #f1f1f1;
    /* thumb color track color */
}
