body {
    font-family: 'Poppins', sans-serif;
    /* font-size: 1rem; */
    /* font-weight: 400; */
    /* line-height: 1.5; */
    /* color: #293442; */
    /* background-color: #f8f9fc; */
}

.gap-1 {
    margin-right: 0.25rem; /* Adjust according to your needs */
}


/* Modals */

body.modal-open {
    overflow: visible;
}

.modal-content {
    border: none;
    border-radius: 10px;
    box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 0px 11px 1px rgba(0, 0, 0, 0.05);
}

.modal-header {
    border-bottom: 0;
    padding: 25px 30px 10px;
}

.modal-title {
    font-size: 16px;
    color: #293442;
    margin: 0;
    font-weight: 600;
}

.modal-body {
    padding: 25px 30px;
}

.modal-footer {
    padding: 15px 30px;
    border: 0;
}

.modal-header .btn-close {
    padding: 10px;
    margin: -10px -10px -10px auto;
}

.font-p1{
    font-size: 1.5rem;
}
.font-p2{
    font-size: 1.2rem;
}
.font-p3{
    font-size: 1rem;
}
.font-bold{
    font-weight: 600;
}
.font-bolder{
    font-weight: 800;
}


.mdxeditor-popup-container.mdxeditor-rich-text-editor{
    z-index: 999999 !important;
    border: 0px;
}

.mdxeditor-rich-text-editor .dropdown-menu {
    z-index: 999999 !important; /* Adjust the z-index value as needed */
}